@import '../../../styles/customMediaQueries.css';

.root {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    /* background-color: var(--marketplaceColor); */
    background-color: var(--colorWhite);

    @media (--viewportMedium) {
        padding: 50px;
    }
}

.title {
    color: var(--colorWhite);
    padding-bottom: 40px;
    margin: 0;
}

.carsContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    max-width: 90vw;
    margin: 0 auto;

    @media (--viewportMedium) {
        max-width: 60vw;
    }
}

.carContainer {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    gap: 0;
    align-items: center;
    background-color: white;

}

.soldLabel {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 5px;
    transform: translate(-30%, 60%) rotate(-45deg);
    text-align: center;
    min-width: 120px;
    font-size: 11px;
    background-color: var(--marketplaceColorSecondary);
    color: white;

    @media (--viewportMedium) {
        font-size: 13px;
        padding: 5px 10px;
    }

}

.img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    min-height: 120px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.carName {
    color: #CDA07F;
    font-size: 16px;
    font-weight: 800px;
    padding: 0;
    margin: 0;


    @media (--viewportMedium) {
        font-size: 20px;
        margin-top: 6px;
    }
}

.carPrice {
    color: black;
    font-size: 12px;
    font-weight: 400px;
    padding: 0;
    margin: 0;


    @media (--viewportMedium) {
        font-size: 14px;
        margin-bottom: 6px;
    }
}