@import '../../../styles/customMediaQueries.css';

.root {
    display: flex;
    flex-direction: column;
    background-image: url('./images/VERKAUF.CH (5).png');
    background-size: cover;
    background-position: top;
    text-align: start;
    /* height: 100px; */
    height: 300px;

    @media (--viewportMedium) {
        min-height: 500px;
        height: unset;
    }
}

.title {
    max-width: 50%;
    color: white;
    font-weight: 800;
    margin-left: 10vw;
    margin-top: 4vh;
    font-size: 30px;
    padding: 0;
    line-height: 30px;
    text-shadow: 2px 2px 4px #000000;

    @media (--viewportMedium) {
        margin-top: 10vh;
        font-size: 60px;
        line-height: 70px;
    }
}

.titleHighlight {
    composes: title;
    display: flex;
    flex-direction: column;
    margin-left: 10vw;
    margin-top: 0;
    color: var(--marketplaceColor);
}

.stars {
    width: 120px;
    margin-left: 30px;

    @media (--viewportMedium) {
        width: 220px;
        margin-left: 90px;
    }
}

.info {
    font-size: 26px;
    line-height: 30px;
    font-weight: 900;
    color: white;
    margin-left: 10vw;

    text-shadow: -3px 0 var(--marketplaceColorSecondary), 0 3px var(--marketplaceColorSecondary), 3px 0 var(--marketplaceColorSecondary), 0 -3px var(--marketplaceColorSecondary);

    @supports(-webkit-text-stroke: 1px black) {
        -webkit-text-stroke: 2px var(--marketplaceColorSecondary);
        text-shadow: none;
    }

    @media (--viewportMedium) {
        margin-top: 10vh;
        font-size: 46px;
        line-height: 50px;
    }
}