@import '../../../styles/customMediaQueries.css';

.custom_prev,
.custom_next {
    display: none;

    @media (--viewportMedium) {
        display: flex;
        height: 100%;
        z-index: 3;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: var(--colorWhite);

        svg {
            height: 60px;
            width: 60px;
        }

        &:hover {
            color: var(--marketplaceColor);
        }
    }

    @media (--viewportLarge) {
        svg {
            height: 120px;
            width: 120px;
        }
    }
}