.root {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    background-color: var(--marketplaceColorSecondary);
}


.title {
    color: var(--colorWhite);
    margin-bottom: 30px;
}

.sliderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.swiper {
    width: 60vw;
    /* height: 400px; */
    display: flex;

    flex-direction: column;
}

.dummyBlackCard {
    background-color: var(--colorBlack);
    border-radius: 20px;
    padding-bottom: 100%;
    aspect-ratio: 16/9;
}

.card {
    background-color: var(--colorWhite);
    border-radius: 20px;
}

.reviewerImage {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    object-fit: cover;
}

.review {
    color: var(--marketplaceColor);
    text-align: center;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 72px;
}

.button {
    /* width: 160px; */
    color: var(--marketplaceColorSecondary);
    background-color: var(--colorWhite);
    margin-top: 30px;
    min-height: 40px;
    padding: 0 5px 0 15px;
    display: flex;
    align-items: center;

    svg {
        fill: var(--marketplaceColor);
        stroke: var(--marketplaceColor);
        transition: var(--transitionStyleButton);
    }

    &:hover {
        svg {
            fill: var(--colorBlack);
            stroke: var(--colorBlack);
        }
    }
}