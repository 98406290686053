@import '../../../styles/customMediaQueries.css';

.root {
    display: flex;
    flex-direction: column;
    height: unset;
    align-items: center;
    background-color: var(--marketplaceColor);

    @media (--viewportMedium) {
        height: 140px;
        flex-direction: row;
    }
}

.title {
    font-size: 26px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
    width: 40%;
    line-height: 44px;
    text-align: center;
    color: white;

    @media (--viewportMedium) {
        font-size: 26px;
        padding: 0;
    }
}

.carDetailsContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: space-evenly;
    background-color: #545454;
    width: 100%;
    height: 100%;
    padding: 20px;

    @media (--viewportMedium) {
        flex-direction: row;
        width: 70%;
        padding-bottom: unset;
    }
}

.dropdown {
    width: 220px;
    border-radius: 0;
    font-size: 14px;

    @media (--viewportMedium) {
        width: 100%;
    }
}

.carDetailsLabel {
    color: white;
    font-size: 16px;
    font-weight: 400;

    @media (--viewportMedium) {
        font-size: 22px;
    }
}

.button {
    width: 220px;
    color: black;
    margin-top: 30px;
}

a {
    &:hover {
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
    }
}