@import '../../../styles/customMediaQueries.css';

.root {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    background-color: var(--colorWhite);
    /* width: 100vw; */
}


.title {
    color: var(--marketplaceColorSecondary);
    font-size: 24px;
    margin-bottom: 30px;
    padding-left: 54px;
}

.columnList {
    column-count: 1;
    column-gap: 40px;
    font-size: 20px;
    padding-bottom: 20px;

    @media (--viewportMedium) {
        column-count: 2;
    }
}

.listItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
}

.checkIcon {
    height: 24px;
    width: 24px;
}

.button {
    margin-top: 30px;
    min-height: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
}