.root {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    background-color: var(--colorWhite);
}

.intro {
    color: var(--colorDark);
    max-width: 830px;
}

.title {
    color: var(--marketplaceColorSecondary);
    margin-bottom: 30px;
}

.stepsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    max-width: 60vw;
}

.step {
    border: 4px solid var(--marketplaceColor);
    flex-direction: column;
    flex: 1;
    max-width: 250px;
    border-radius: var(--borderRadiusMedium);

    h3 {
        color: var(--marketplaceColorSecondary);
        font-size: 20px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: center;
        line-height: 30px;
        padding: 0 10px;
        margin-top: 10px;
    }

    p {
        color: var(--colorDark);
        padding: 0 10px;
        text-align: center;
    }
}

.outro {
    composes: intro;
    text-align: center;
    margin-top: 30px;
}

.button {
    /* width: 160px; */
    color: var(--colorBlack);
    margin-top: 30px;
    min-height: 40px;
    padding: 0 15px;
}