@import '../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    background-color: var(--colorWhite);
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 30px;

    @media (--viewportLarge) {
        flex-direction: row;
        margin: 0 auto;
        padding: 120px 0;
        gap: 3vw;
        width: var(--contentMaxWidthPages);
    }
}